define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de-de", {
    "app": {
      "env": "Umgebung",
      "footer": {
        "documentation": "Dokumentation",
        "language": "Sprache",
        "licenses": "Lizenzen",
        "mail": "E-Mail",
        "report_issue": "Problem melden",
        "slack": "Slack"
      },
      "logout": "Ausloggen {name}",
      "menu": {
        "admin": "Admin",
        "colleagues": "Kollegen",
        "pan": "PAn",
        "reporting": "Berichte"
      },
      "menu_entry": "Menü",
      "name": "Offline",
      "page": {
        "abroad": "Auslandseinsatz",
        "abroad-reporting": "Auslandseinsatzberichte",
        "admin-projects": "Projekte",
        "admin-retreats": "Retreats",
        "admin-techdays": "Techdays",
        "colleagues": "Kollegenabfrage",
        "dashboard": "Übersicht",
        "excelexport-absences": "Excelexport von Abwesenheiten",
        "myabsencesnew": "Kalender",
        "pan-absences": "Abwesenheiten",
        "pan-pans": "PAns",
        "pan-periods": "Zeiträume",
        "pan-reporting": "Berichte",
        "pan-teams_assignments": "Teams",
        "pan-utilities": "Werkzeuge",
        "plc": "Project Lifecycle Management",
        "profile": "Profil",
        "roles": "Rollen",
        "settings": "Einstellungen",
        "team-calendar": "Teamkalender"
      },
      "remote-model": {
        "delete": "Löschen erfolgreich",
        "delete_fail": "Fehler beim Löschen",
        "load_fail": "Fehler beim Laden der Daten",
        "load_history": "Fehler beim Laden der Historie",
        "save": "Speichern erfolgreich",
        "save_fail": "Fehler beim Speichern"
      },
      "session-service": {
        "admin_projects": "Es konnten keine Projekte zum Administrieren gefunden",
        "language_error": "Sprache konnte nicht geändert werden"
      },
      "unexpected_error": "Ein unerwarteter Fehler ist aufgetreten: {error}"
    },
    "calendar": {
      "month": {
        "april": "April",
        "august": "August",
        "december": "Dezember",
        "february": "Februar",
        "january": "Januar",
        "july": "Juli",
        "june": "Juni",
        "march": "März",
        "may": "Mai",
        "november": "November",
        "october": "Oktober",
        "september": "September"
      },
      "month-shorthand": {
        "Apr": "Apr",
        "Aug": "Aug",
        "Dec": "Dez",
        "Feb": "Feb",
        "Jan": "Jan",
        "Jul": "Jul",
        "Jun": "Jun",
        "Mar": "Mär",
        "May": "Mai",
        "Nov": "Nov",
        "Oct": "Okt",
        "Sep": "Sep"
      }
    },
    "component": {
      "abroad-actions": {
        "delete": "Löschen"
      },
      "absence-table": {
        "absence": "Abwesenheit",
        "duration": "Dauer",
        "no_absences": "Du hast keine ausstehenden Abwesenheiten."
      },
      "calendar_month": {
        "friday": "Fr",
        "monday": "Mo",
        "saturday": "Sa",
        "sunday": "So",
        "thursday": "Do",
        "tuesday": "Di",
        "wednesday": "Mi"
      },
      "date_picker": {
        "use_today": "Heute wählen"
      },
      "project-assignment-table": {
        "no_assignments": "Aktuell liegen keine Projektzuordnungen vor.",
        "project_name": "Projekt",
        "timeframe": "Zeitraum"
      },
      "project-details": {
        "actions": {
          "cancel": "Abbrechen",
          "delete": "Löschen",
          "edit": "Bearbeiten",
          "save": "Speichern",
          "to-project-overview": "Zur Projektübersicht"
        },
        "auto_approval": "Autogenehmigung",
        "auto_withdrawal": "Auto. Zurückziehen",
        "client-id": "Techn. Kunden-Id",
        "ldap_group": "LDAP-Gruppe",
        "pan-list": "Projektansprechpartner",
        "phase": "Phase",
        "project_key": "Projektschlüssel",
        "project_name": "Name",
        "status": "Status"
      },
      "range_picker": {
        "day": "Tag",
        "month": "Monat",
        "view_end": "Ende",
        "view_start": "Start",
        "year": "Jahr"
      }
    },
    "data_model": {
      "absence": {
        "comment": "Kommentar",
        "daysOff": {
          "day": "Tag",
          "days": "Tage",
          "daysTooltip": "Es handelt sich hierbei um die Gesamtanzahl der Absenztage einschließlich Wochenenden und Feiertagen.",
          "halfDay": "halber",
          "halfDays": "halbe"
        },
        "employee": "Mitarbeiter",
        "endDate": "Enddatum",
        "half_day": "Halber Tag",
        "startDate": "Startdatum",
        "status": "Status",
        "type": "Typ"
      },
      "absence_status": {
        "approved": "Genehmigt",
        "draft": "Entwurf",
        "new": "Neu",
        "rejected": "Abgelehnt",
        "submitted": "Beantragt",
        "withdrawn": "Zurückgezogen"
      },
      "absence_type": {
        "holiday": "Feiertag",
        "illness": "Krankheit",
        "none": "",
        "project_absence": "Projektabwesenheit",
        "special_absence": "Sonderabwesenheit",
        "techday": "Techday",
        "training": "Fortbildung",
        "vacation": "Urlaub"
      }
    },
    "language": {
      "de-de": "Deutsch",
      "en-us": "English"
    },
    "modal": {
      "absence": {
        "action": {
          "cancel": "Abbrechen",
          "submit": "Speichern"
        },
        "child_name": "Name des Kindes",
        "end_date": "Enddatum",
        "send_mail": "Mail senden",
        "start_date": "Startdatum",
        "status": "Status",
        "title": "Abwesenheit bearbeiten",
        "type": "Typ"
      },
      "confirmation": {
        "action": {
          "no": "Nein, doch nicht.",
          "yes": "Oh ja!"
        },
        "defaultTitle": "Bestätigung erforderlich",
        "description": "Willst du das wirklich?"
      }
    },
    "page": {
      "abroad": {
        "description": "Übersicht der im Ausland gearbeiteten Tage. Falls der Haupteinsatzort per Vertag Home Office ist, müssen alle nicht im Home Office gearbeiteten Tage hier eingetragen werden, selbst wenn nicht im Ausland gearbeitet wird.",
        "new": {
          "endDate": "Bis",
          "save": "Speichern",
          "startDate": "Von"
        },
        "table": {
          "actions": "Aktionen",
          "endDate": "Bis",
          "location": "Ort",
          "startDate": "Von"
        },
        "title": "Auslandseinsatzübersicht"
      },
      "abroad-reporting": {
        "table": {
          "employee": "Mitarbeiter",
          "endDate": "Bis",
          "location": "Ort",
          "no_data": "Keine Daten vorhanden",
          "startDate": "Von"
        },
        "title": "Auslandseinsatzbericht"
      },
      "absences": {
        "absences": {
          "new_illness": "Neue Krankheit",
          "new_project_absence": "Neue Projektabwesenheit",
          "new_special_absence": "Neue Sonderabwesenheit",
          "new_techday": "Neuer Techday",
          "new_training": "Neue Fortbildung",
          "new_vacation": "Neuer Urlaub"
        },
        "dialog": {
          "confirmDeleteAbsence": {
            "title": "Abwesenheit löschen"
          },
          "edit": {
            "action": {
              "cancel": "Abbrechen",
              "reset": "Zurücksetzen",
              "save": "Speichern"
            },
            "approved": "Genehmigen",
            "half_day": "Halber Tag",
            "send_mail": "Mail senden",
            "title": "Abwesenheit bearbeiten"
          },
          "history": {
            "action": {
              "close": "Schließen"
            },
            "column": {
              "change_comment": "Änderungskommentar",
              "change_date": "Änderungsdatum",
              "duration": "Dauer",
              "end_date": "Enddatum",
              "processor": "Bearbeiter",
              "start_date": "Startdatum",
              "status": "Status",
              "type": "Typ"
            },
            "title": "Historie der Abwesenheit"
          }
        },
        "end_date": "Enddatum",
        "menu": {
          "accept_withdrawal": "Zurückziehen annehmen",
          "approve": "Genehmigen",
          "cancel_modify": "Bearbeiten abbrechen",
          "cancel_moving": "Verschieben abbrechen",
          "change": "Bearbeiten",
          "change_type": {
            "type_illness": "Typ ändern auf Krankheit",
            "type_project_absence": "Typ ändern auf Projektabwesenheit",
            "type_special_absence": "Typ ändern auf Sonderabwesenheit",
            "type_techday": "Typ ändern auf Techday",
            "type_training": "Typ ändern auf Fortbildung",
            "type_vacation": "Typ ändern auf Urlaub"
          },
          "delete": "Löschen",
          "move": "Verschieben",
          "reapprove": "Erneut genehmigen",
          "reject": "Ablehnen",
          "reset_to_approved": "Auf 'Genehmigt' zurücksetzen",
          "show_history": "Historie anzeigen",
          "submit": "Speichern",
          "submit_moving": "Hierhin verschieben"
        },
        "more_than_one_absence": "Es gibt mehr als eine Abwesenheit:",
        "select_period": "Zeitraum wählen",
        "select_period_prompt": "<Kein Zeitraum>",
        "select_project": "Projekt",
        "select_project_prompt": "Projekt wählen",
        "select_team": "Team",
        "start_date": "Startdatum",
        "title": "Abwesenheiten"
      },
      "admin-absences": {
        "absences": "Fehler beim Laden der Abwesenheiten",
        "already_techday": "Hier ist bereits ein Techday. Wähle einen anderen Tag!",
        "comment": "Bitte Kommentar hinzufügen",
        "holidays": "Fehler beim Laden der Feiertage",
        "no_data": "Keine Daten",
        "no_friday": "Wähle einen Freitag!",
        "project_periods_retrieve": "Fehler beim Laden der Zeiträume",
        "projects_retrieve": "Fehler beim Laden der Projekte",
        "select_current": "Aktueller Zeitraum",
        "select_team": "<Team Auswählen>",
        "set_end": "Enddatum setzen",
        "set_start": "Startdatum setzen",
        "team_all": "<Alle Teams>",
        "team_none": "<Ohne Team>",
        "team_surname": "<Nachname>",
        "warning_too_big": "Die Anzeige aller Projektmitglieder kann unter Umständen länger dauern."
      },
      "admin-retreats": {
        "dialog": {
          "confirm_delete_retreat_title": "Retreat löschen"
        },
        "empty_fields": "Bitte alle Felder ausfüllen.",
        "friday": "Tag muss ein Freitag sein!",
        "workday": "Tag darf kein Sonntag sein!"
      },
      "admin-techdays": {
        "create_error": "Fehler beim erzeugen der Techdays für {year}",
        "created": "{count} Techdays für {year} erzeugt",
        "fixed_retrieve": "Fehler beim Laden der festen Techdays",
        "friday": "Tag muss ein Freitag sein!"
      },
      "admin-utils": {
        "employee_select": "Bitte einen Mitarbeiter auswählen",
        "mail_error": "Fehler beim Versenden der E-Mail",
        "project_select": "Bitte Projekt auswählen",
        "projects_retrieve": "Fehler beim Laden der Projekte"
      },
      "admin_utils": {
        "action": {
          "send": "Absenden",
          "test": "Testen"
        },
        "addtional_comment": "Zusätzlicher Kommentar",
        "cc_to_project": "Kopie an Project",
        "for_employee": "Für den Mitarbeiter",
        "for_project": "Für das Projekt",
        "no_project_selected": "<Kein Projekt ausgewählt>",
        "test_result": "Testergebnisse",
        "title": "Abwesenheitsinformationsmails versenden"
      },
      "assignments": {
        "assignment_list_header": "Mitarbeiter zugeordnet zu",
        "assignment_synced": "Zuordnungen über die LDAP-Gruppe {ldap_group} gepflegt",
        "dialog_assignment": {
          "action": {
            "cancel": "Abbrechen",
            "delete": "Zuordnung löschen",
            "reset": "Zurücksetzen",
            "save": "Änderungen speichern",
            "save_new": "Zuordnung speichern"
          },
          "employee": "Mitarbeiter",
          "end_date": "Enddatum",
          "fixed_end_date": "Festes Enddatum",
          "header": "Details der Zuordnung von",
          "percentage": "Anteil",
          "percentage_placeholder": "% der Zuordnung",
          "roles": "Rollen",
          "team": "Team",
          "title": "Zuordnung bearbeiten"
        },
        "dialog_team": {
          "action": {
            "cancel": "Abbrechen",
            "delete": "Team löschen",
            "save": "Änderungen speichern",
            "save_new": "Team speichern"
          },
          "name": "Name",
          "name_placeholder": "Teamname",
          "title": "Team bearbeiten"
        },
        "dialog_team_delete_confirm": {
          "description": "Das Löschen eines Teams beendet auch alle Zuordnungen für dieses Team!",
          "title": "Teamlöschung bestätigen"
        },
        "employee_select": "Bitte einen Mitarbeiter auswählen",
        "filter": "Filter",
        "project": {
          "filter": "Filter",
          "header": "Projekt",
          "no_project_selected": "Kein Projekt gewählt",
          "select_project": "Projekt wählen"
        },
        "project_select": "Bitte ein existierendes Projekt auswählen",
        "projects_retrieve": "Fehler beim Laden der Projekte",
        "team": {
          "action": {
            "edit_team": "Team Bearbeiten",
            "new_assignment": "Neue Zuordnung",
            "new_team": "Neues Team"
          },
          "filter": "Filter",
          "header": "Teams",
          "no_project_selected": "Bitte Projekt wählen",
          "select_team": "Wähle ein Team",
          "show_all_members": "Zeige alle Projektmitglieder",
          "teams_of": "Teams von {project}"
        },
        "title": "Zuordnungen",
        "types_retrieve": "Fehler beim Laden der Rollen"
      },
      "colleagues": {
        "absences_retrieve": "Fehler beim Laden der Abwesenheiten",
        "return": "Voraussichtlich wieder im Projekt am {date}.",
        "search": "Suche",
        "status": {
          "not_available": "Heute nicht verfügbar.",
          "present": "Heute verfügbar.",
          "project_absent": "Heute nicht für das Projekt verfügbar.",
          "techday": "Heute auf dem Techday."
        },
        "title": "Kollegenabfrage"
      },
      "dashboard": {
        "absences": "Fehler beim Laden der eigenen Abwesenheiten",
        "action": {
          "accept_withdrawal": "Zurückziehen annehmen",
          "approve": "Genehmigen",
          "child_ill_today": "Heute Kind krankmelden",
          "edit": "Bearbeiten",
          "extend_illness": "Einreichen",
          "ill_today": "Heute krankmelden",
          "reject": "Ablehnen",
          "reset_to_approved": "Auf 'Genehmigt' zurücksetzen"
        },
        "actionable": "Fehler beim Laden der zu bearbeitenden Abwesenheiten",
        "expand": "Vergrößern",
        "section": {
          "actionable_absences": {
            "actions": "Aktionen",
            "auto_approval_status": {
              "can_be_auto_approved": "Wird 5 Arbeitstage nach Anfragedatum automatisch genehmigt",
              "not_a_vacation": "Nur Urlaube können automatisch genehmigt werden",
              "project_with_no_auto_approval": "Der Mitarbeiter hat ein Projekt, das die automatische Genehmigung blockiert",
              "too_long": "Kann nicht automatisch genehmigt werden, da Abwesenheit länger als 14 Tage",
              "too_recent": "Anfrage zu kurzfristig. Abwesenheiten, deren Startdatum innerhalb von 5 Arbeitstagen nach dem Einreichen liegt, können nicht automatisch genehmigt werden"
            },
            "duration": "Dauer",
            "hint": {
              "text": {
                "can_be_auto_approved": "Automatische Genehmigung aktiviert",
                "not_a_vacation": "Automatische Genehmigung deaktiviert",
                "project_with_no_auto_approval": "Automatische Genehmigung deaktiviert",
                "too_long": "Automatische Genehmigung deaktiviert",
                "too_recent": "Automatische Genehmigung deaktiviert"
              },
              "title": "Hinweis",
              "tooltip": "Diese Abwesenheit ist länger als zwei Wochen oder wurde zu spät eingereicht. Sie wird deshalb nicht automatisch genehmigt."
            },
            "no_absences": "Du musst keine Abwesenheiten bearbeiten.",
            "project": "Projekt",
            "team": "Team",
            "title": "Abwesenheiten zur Bearbeitung"
          },
          "current_illness": {
            "date_pick": "Enddatum der aktuellen Krankheit ändern auf",
            "title": "Aktuelle Krankheit"
          },
          "my_absences": {
            "title": "Meine ausstehenden Abwesenheiten"
          }
        },
        "shrink": "Verkleinern",
        "title": "Offline"
      },
      "error": {
        "back_to": "Zurück zur",
        "dashboard": "Übersicht",
        "message": "Upps, da ist etwas schiefgelaufen."
      },
      "my_absences": {
        "absence": {
          "comment": "Kommentar",
          "comment_missing": "Bitte Kommentar eingeben",
          "documentation": "Dokumentation",
          "end_date": "Enddatum",
          "end_date_before_start_date": "Das Enddatum muss nach dem Startdatum liegen",
          "half_day": "Halber Tag",
          "info_overtime_days": "Hinweis: Unterstundentage werden als Urlaub eingetragen.",
          "info_types": "Für mehr Informationen über die Typen von Abwesenheiten: siehe ",
          "new_comment": "Neuer Kommentar",
          "previous_comment": "Alter Kommentar",
          "start_date": "Startdatum",
          "start_date_missing": "Bitte Startdatum auswählen",
          "status": "Status",
          "type": "Typ",
          "type_missing": "Bitte einen Typ auswählen"
        },
        "absences_retrieve": "Fehler beim Laden der Abwesenheiten",
        "action": {
          "abort": "Abbrechen",
          "delete": "Löschen",
          "reset": "Auf Entwurf zurücksetzen",
          "resubmit": "Erneut beantragen",
          "save_draft": "Entwurf speichern",
          "show_history": "Historie anzeigen",
          "submit": "Beantragen",
          "unselect": "Abwählen",
          "withdraw": "Zurückziehen"
        },
        "approved_retrieve": "Fehler beim Laden der genehmigten Urlaubstagen",
        "create_absence_on_techday": "Erstelle Abwesenheit am Techday",
        "create_new_absence": "Neue Abwesenheit erstellen",
        "edit_absence": "Abwesenheit bearbeiten",
        "holidays_retrieve": "Fehler beim Laden der Feiertage",
        "messages": {
          "overlapping": "Die Abwesenheit würde sich mit einer anderen überlappen.Wollen Sie vielleicht eine neue Krankheit eintragen?"
        },
        "section": {
          "legend": {
            "status": {
              "approved": "Genehmigt",
              "cancelled": "Abgebrochen",
              "draft": "Entwurf",
              "rejected_cancelled": "Abgelehnt/Abgebrochen",
              "standard": "Standard",
              "submitted": "Beantragt",
              "withdrawn": "Zurückgezogen"
            },
            "title": "Legende",
            "type": {
              "halfDayAbsence": "Halber Tag",
              "holiday": "Feiertag",
              "illness": "Krankheit",
              "mandatory": "Freizuhalten",
              "misc": "Allg.",
              "normal_day": "Normaler Tag",
              "project_absence": "Projektabwesenheit",
              "special_absence": "Sonderabwesenheit",
              "techday": "Techday",
              "today": "Heute",
              "training": "Fortbildung",
              "vacation": "Urlaub",
              "weekend": "Wochenende"
            }
          },
          "summary": {
            "count": "Anzahl",
            "no_vacation": "Du hast gerade keine genehmigten Urlaube für das laufende Jahr.",
            "title": "Übersicht der genehmigten Urlaubstage",
            "year": "Jahr"
          }
        },
        "select": "Auswählen",
        "title": "Kalender"
      },
      "pans": {
        "action": {
          "cancel": "Abbrechen",
          "delete": "Löschen",
          "save": "Speichern"
        },
        "employee": "Mitarbeiter",
        "employee_select": "Bitte einen Mitarbeiter auswählen",
        "new_pan": "Neuer PAn",
        "project_pans": "Fehler beim Laden PAns für das Projekt",
        "project_select": "Bitte Projekt auswählen",
        "projects_retrieve": "Fehler beim Laden der Projekte",
        "select_pan": "PAn auswählen",
        "select_project": "Projekt auswählen",
        "select_project_prompt": "<Kein Projekt gewählt>",
        "title": "PAn"
      },
      "periods": {
        "action": {
          "cancel": "Abbrechen",
          "delete": "Löschen",
          "new": "Neuer Zeitraum",
          "save": "Speichern"
        },
        "end_date": "Enddatum",
        "filter": "Filter",
        "filter_placeholder": "",
        "messages": {
          "project_name": "Bitte einen Namen eingeben",
          "project_periods_retrieve": "Fehler beim Laden von Zeiträumen für das Projekt",
          "project_select": "Bitte ein existierendes Projekt auswählen",
          "projects_retrieve": "Fehler beim Laden der Projekte"
        },
        "name": "Name",
        "name_placeholder": "Zeitraumname",
        "project": "Projekt",
        "select_period": "Zeitraum auswählen",
        "select_project": "Projekt auswählen",
        "select_project_prompt": "<Kein Projekt gewählt>",
        "start_date": "Startdatum",
        "title": "Zeitraum"
      },
      "profile": {
        "absences": "Abwesenheiten",
        "code": "LDAP-Code",
        "error": {
          "something-went-wrong": "Etwas ist schief gelaufen. Das Profil konnte nicht geladen werden."
        },
        "hideTechdays": "Techdays ausblenden",
        "name": "Name",
        "profile": "Profil",
        "projects": "Projekte",
        "showTechdays": "Techdays einblenden"
      },
      "projects": {
        "action": {
          "cancel": "Abbrechen",
          "delete": "Löschen",
          "edit": "Projekt Bearbeiten",
          "new": "Neues Projekt",
          "save": "Speichern",
          "save_new": "Anlegen"
        },
        "auto_approval": "Automatische Urlaubsgenehmigung nach 5 Arbeitstagen",
        "auto_withdrawal": "Zurückziehen von Abwesenheiten ohne Genehmigung",
        "client_id": "Interne Kunden ID",
        "client_id_placeholder": "Technische interne Kunden ID",
        "default_project_name": "Neues Projekt",
        "employee": "Mitarbeiter",
        "filter": "Nach Projektnamen filtern...",
        "ldap": "LDAP-Gruppe",
        "ldap_placeholder": "Projekt LDAP-Gruppe",
        "project_key": "Projektschlüssel",
        "project_key_placeholder": "Extern referenzierbarer Projektschlüssel",
        "projects_retrieve": "Fehler beim Laden der Projekte",
        "select_project": "Projekt auswählen",
        "select_project_prompt": "<Keine Projekt gewählt>",
        "title": "Projekte"
      },
      "reporting": {
        "as_of_label": "Zu Zeitpunkt",
        "capacity_retrieve": "Fehler beim Laden der Kapazität",
        "date_from": "Von",
        "date_to": "Bis",
        "diff": "Differenz",
        "diff_to": "Differenz zu",
        "download": "Download Excel",
        "download_failed": "Download fehlgeschlagen",
        "export_to_excel": "Export nach Excel",
        "no_date": "Wähle ein Datum aus!",
        "number_format": "Zahlenformat",
        "project": "Projekt",
        "project_periods_retrieve": "Fehler beim Laden der Zeiträume für das Projekt",
        "project_prompt": "Projekt wählen",
        "projects_retrieve": "Fehler beim Laden der Projekte",
        "report": {
          "column": {
            "employee": "Mitarbeiter",
            "gross_days": "Bruttotage",
            "illness": "Krankheit",
            "net_days": "Nettotage",
            "project": "Projekt",
            "project_absence": "Projektabwesenheit",
            "role": "Rolle",
            "special_absence": "Sonderabwesenheit",
            "team": "Team",
            "techday": "Techday",
            "training": "Training",
            "vacation": "Urlaub"
          }
        },
        "roles": "Rollen",
        "select_absenceTypes": "Abwesenheitsarten auswählen",
        "select_period": "Zeitraum auswählen",
        "select_period_prompt": "<Kein Zeitraum>",
        "select_site": "Standort auswählen",
        "show_by": "Anzeigen nach",
        "show_individuals": "Einzelpersonen",
        "sites": "Fehler beim Laden der Standorte",
        "subtitle": "Excelexport von Abwesenheiten",
        "teams": "Teams",
        "title": "Berichte"
      },
      "retreat": {
        "action": {
          "cancel": "Abbrechen",
          "create_new": "Neuen Retreat hinzufügen",
          "delete_selected": "Ausgewählten Retreat löschen",
          "save": "Speichern"
        },
        "details_section": {
          "date1": "Tag 1",
          "date2": "Tag 2",
          "date3": "Tag 3",
          "date4": "Tag 4",
          "ldap1": "Gruppe 1",
          "ldap2": "Gruppe 2",
          "ldap3": "Gruppe 3",
          "ldap4": "Gruppe 4",
          "name": "Retreat-Name",
          "name_description": "Name hier eingeben",
          "replaced13": "Zyklus 1/3",
          "replaced24": "Zyklus 2/4",
          "title": {
            "create": "Retreat erstellen",
            "date": "Datum",
            "edit": "Retreat bearbeiten",
            "fourthDay": "Hat vier Tage",
            "hasReplacementTechday": "Ersetzender Techday für Abwesende des Retreats",
            "ldap": "LDAP-Gruppe",
            "new": "Neuen Retreat erstellen",
            "replaced": "Ersetzte Techdays"
          }
        },
        "table_title": "Übersicht",
        "title": "Retreats"
      },
      "roles": {
        "action": {
          "cancel": "Abbrechen",
          "delete": "Löschen",
          "new": "Neue Rolle",
          "save": "Speichern"
        },
        "description": "Beschreibung",
        "name": "Name",
        "name_placeholder": "Rollenname",
        "project": "Projekt",
        "title": "Projekt-Rollen"
      },
      "settings": {
        "action": {
          "save": "Speichern"
        },
        "choose_locale_error": "Bitte wähle eine Sprache aus.",
        "configure_actionable_absences": "PAn Abwesenheiten konfigurieren",
        "configure_actionable_absences_explanation": "Entscheide welche der folgenden Informationen über Abwesenheiten du sehen willst, ohne die Abwesenheit vergrößern zu müssen.",
        "current_site": "Aktueller Standort",
        "hint_team_only_with_projekt": "(Das Team kann nur angezeigt werden, wenn auch das Projekt angezeigt wird.)",
        "language_settings": "Spracheinstellungen",
        "locale_changed_success": "Sprache geändert.",
        "select_language": "Sprachwahl",
        "sharing_absences_with_project": "Abwesenheiten für das Projekt freigeben",
        "sharing_absences_with_project_explanation": "Diese Einstellung legt fest, ob deine Team- oder Projektkollegen (wenn es keine Subteams gibt) deinen Anwesenheitsstatus im Teamkalender für die nähere Zukunft sehen können (z.B. zwei Wochen). Wenn dein Anwesenheitsstatus sichtbar ist, können die Kolleginnen sehen ob du anwesend, abwesend (aber nicht den Grund der Abwesenheit, z.B. Urlaub oder Krankheit), abwesend vom Projekt (z.B. auf einer Fortbidung) oder am Techday bist. Andernfalls wird dein Status als unbekannt dargestellt. Die Abfrage des Anwesenheitsstatus eines beliebigen Kollegen für den heutigen Tag ist hiervon nicht betroffen.",
        "site": "Einsatzstandort",
        "timezone_settings": "Zeitzoneneinstellungen",
        "title": "Persönliche Einstellungen"
      },
      "team_calendar": {
        "error": "Der Kalender konnte nicht geladen werden.",
        "legend": {
          "absent": "Abwesend",
          "holiday": "Feiertag",
          "project_absent": "Nicht im Projekt",
          "techday": "Techday",
          "title": "Legende",
          "today": "Heute",
          "weekend": "Wochenende"
        },
        "member_not_sharing": "Abwesenheiten nicht geteilt",
        "no_data": "Keine Daten zu Teammitgliedern gefunden.",
        "title": "Teamkalender"
      },
      "techdays": {
        "section_fixed_techday": {
          "action": {
            "cancel": "Abbrechen",
            "save": "Speichern"
          },
          "fixed_date": "Festes Datum",
          "fixed_techdays": "Feste Techdays",
          "name": "Name",
          "name_description": "Beschreibung",
          "new_fixed_techday": "Neuer fester Techday",
          "replaced13": "Ersetzter Techday 1/3",
          "replaced24": "Ersetzter Techday 2/4",
          "title": "Feste Techdays"
        }
      }
    },
    "project-phase": {
      "CURRENT": "aktiv",
      "FORMER": "ehemalig",
      "NOT_SET": "---",
      "PLANNED": "geplant"
    },
    "project-status": {
      "CRITICAL": "Rot",
      "NOT_SET": "---",
      "OKAY": "Grün",
      "WARNING": "Gelb"
    },
    "rest": {
      "absence_not_found": "Abwesenheit mit der Id {id} wurde nicht gefunden",
      "absence_type_change_only_for_draft": "Die Abwesenheitsart kann nur im Zustand \"Entwurf\" geändert werden",
      "assignment_not_found": "Zuordnung mit der Id {id} wurde nicht gefunden",
      "assignment_still_running": "Derzeit gibt es immernoch Zuordnungen mit der ausgewählten Rolle. Bitte diese vor dem Löschen der Rolle terminieren.",
      "comment_mandatory": "Der Kommentar ist für die gewählte Abwesenheitsart verpflichtend",
      "delete_yourself": "Man kann sich nicht selbst löschen",
      "employee_cannot_be_changed": "Mitarbeiter kann nicht geändert werden",
      "employee_empty_code": "Benutzername muss gesetzt sein",
      "employee_empty_name": "Mitarbeitername muss gesetzt sein",
      "employee_exists": "Es existiert bereits ein Mitarbeiter mit demselben Benutzernamen",
      "employee_non_db": "Mitarbeiter aus dem LDAP können nicht bearbeitet werden",
      "employee_not_found": "Mitarbeiter nicht gefunden",
      "empty_id_for_new": "Id für ein neues Objekt darf nicht gesetzt sein",
      "fixed_techday_not_found": "Fester Techday mit der Id {id} wurde nicht gefunden",
      "id_needed_for_update": "Id muss für eine Aktualisierung gesetzt sein und dem Objekt entsprechen",
      "invalid_new_absence_status": "Status {status} ist für eine neue Abwesenheit nicht erlaubt",
      "invalid_new_end_date": "Das neue Enddatum liegt entweder in der Vergangenheit oder zu weit in der Zukunft",
      "invalid_parameters": "Die übergebenen Parameter sind nicht korrekt",
      "invalid_percentage": "Anteil muss zwischen 0 und 100% liegen",
      "invalid_update_of_absence_status": "Fehler bei der Aktualisierung des Status: {absence} ist nach {existing} nicht gültig",
      "limit_positive": "Limit muss postitiv sein",
      "named_period_not_found": "Zeitraum mit id {id} nicht gefunden",
      "no_absence_status": "Kein Abwesenheitsstatus angegeben",
      "no_absence_type": "Kein Abwesenheitstyp angegeben",
      "no_assignment_type": "Kein Abwesenheitstyp angegeben",
      "no_changes_for_pan_from_ldap": "Änderung der Panzuordung für Projekt aus LDAP nicht erlaubt",
      "no_employee": "Kein Mitarbeiter angegeben oder nicht in der Datenbank gefunden",
      "no_end_date": "Enddatum muss angegeben werden",
      "no_entity_to_update": "Objekt zum Aktualisieren existiert nicht",
      "no_name": "Keinen Namen angegeben",
      "no_null": "Null ist nicht erlaubt",
      "no_project": "Projekt nicht angegeben oder nicht in der Datenbank vorhanden",
      "no_start_date": "Startdatum muss angegeben werden",
      "no_team": "Team nicht angegeben oder nicht in der Datenbank vorhanden",
      "not_admin": "Nur Administratoren dürfen diese Aktion durchführen.",
      "not_admin_or_in_project": "Nur Mitglieder und Administratoren des Projekts können diese Aktion durchführen.",
      "not_logged_in": "Nicht eingeloggt.",
      "only_authorized": "Nur berechtigte Nutzer dürfen diese Aktion durchführen.",
      "only_self": "Nur der entsprechende Mitarbeiter selbst darf diese Aktion durchführen.",
      "overlapping_absences": "Abwesenheit würde sich mit einer anderen Abwesenheit überschneiden (Entwürfe eingeschlossen)",
      "project_all_assignment": "Zuordnung zu Projekt All nicht erlaubt",
      "project_cannot_be_changed": "Projekt kann nicht geändert werden.",
      "project_empty_name": "Projektname darf nicht leer sein.",
      "project_exists": "Es existiert bereits ein Projekt mit demselben Namen.",
      "project_ldap_group_not_found": "Die LDAP-Gruppe des Projekts konnte nicht gefunden werden.",
      "project_settings_not_found": "Projektkonfiguration nicht gefunden.",
      "retreat_ldap_group_not_found": "Die angebene Ldap-Gruppe konnte nicht gefunden werden.",
      "retreat_number_of_days_changed": "Die Anzahl der Retreat-Tage hat sich geändert. Das ist nicht unterstützt.",
      "team_empty_name": "Teamname darf nicht leer sein.",
      "team_exists": "Es existiert bereits ein Team mit demselben Namen.",
      "team_project_cannot_be_changed": "Das Projekt eines Teams kann nicht geändert werden.",
      "tech_days_only_1_day": "Techdays dürfen nur einen Tag dauern.",
      "techdays_description_missing": "Bitte Beschreibung angeben.",
      "techdays_fixed_missing": "Für das Jahr {year} konnten keine festen Techdays gefunden werden! Bitte diese zuerst anlegen!",
      "techdays_friday": "Techday muss an einem Freitag sein.",
      "timespan_not_forever": "Das Ende eines Zeitraums darf nicht \"für immer\" sein.",
      "timespan_order": "Das Ende des Zeitraums ist vor dem Start",
      "unexpected_error": "Ein unerwarteter Fehler ist aufgetreten! {error}",
      "validation_error": "Validierungsfehler",
      "wrong_date_order": "Startdatum muss vor dem Enddatum liegen"
    },
    "site": {
      "AUGSBURG": "Augsburg",
      "AUSTRALIA_VICTORIA": "Australien / Victoria",
      "AUSTRIA": "Österreich",
      "BADEN_WUERTTEMBERG": "Baden-Württemberg",
      "BAYERN_EV": "Bayern (ev.)",
      "BAYERN_KATH": "Bayern (kath.)",
      "BERLIN": "Berlin",
      "BRANDENBURG": "Brandenburg",
      "BREMEN": "Bremen",
      "BULGARIA": "Bulgarien",
      "ETTENHEIM": "Ettenheim",
      "HAMBURG": "Hamburg",
      "HESSEN": "Hessen",
      "HUNGARY": "Ungarn",
      "KARLSRUHE": "Karlsruhe",
      "MECKLENBURG_VORPOMMERN": "Mecklenburg-Vorpommern",
      "MUNICH": "München",
      "NIEDERSACHSEN": "Niedersachsen",
      "NORDRHEIN_WESTFALEN": "Nordrhein-Westfalen",
      "NUERNBERG": "Nürnberg",
      "REGENSBURG": "Regensburg",
      "REUTLINGEN": "Reutlingen",
      "RHEINLAND_PFALZ": "Rheinland-Pfalz",
      "SAARLAND": "Saarland",
      "SACHSEN": "Sachsen",
      "SACHSEN_ANHALT": "Sachsen-Anhalt",
      "SCHLESWIG_HOLSTEIN": "Schleswig-Holstein",
      "STUTTGART": "Stuttgart",
      "SWITZERLAND_VAUD": "Schweiz / Waadt",
      "THUERINGEN": "Thüringen"
    }
  }], ["en-us", {
    "app": {
      "env": "Environment",
      "footer": {
        "documentation": "Documentation",
        "language": "Language",
        "licenses": "Licenses",
        "mail": "Email",
        "report_issue": "Report Issue",
        "slack": "Slack"
      },
      "logout": "Logout {name}",
      "menu": {
        "admin": "Admin",
        "colleagues": "Colleagues",
        "pan": "PAn",
        "reporting": "Reporting"
      },
      "menu_entry": "Menu",
      "name": "Offline",
      "page": {
        "abroad": "Working Abroad",
        "abroad-reporting": "Abroad Reporting",
        "admin-projects": "Projects",
        "admin-retreats": "Retreats",
        "admin-techdays": "Techdays",
        "colleagues": "Query Colleague",
        "dashboard": "Dashboard",
        "excelexport-absences": "Excelexport of Absences",
        "myabsencesnew": "Calendar",
        "pan-absences": "Absences",
        "pan-pans": "PAns",
        "pan-periods": "Periods",
        "pan-reporting": "Reporting",
        "pan-teams_assignments": "Teams",
        "pan-utilities": "Utilities",
        "plc": "Project Lifecycle Management",
        "profile": "Profile",
        "roles": "Roles",
        "settings": "Settings",
        "team-calendar": "Team Calendar"
      },
      "remote-model": {
        "delete": "Deleted successfully",
        "delete_fail": "Error deleting data",
        "load_fail": "Error loading data",
        "load_history": "Error loading history data",
        "save": "Saved successfully",
        "save_fail": "Error storing data"
      },
      "session-service": {
        "admin_projects": "Was not able to find out if there are projects to administer",
        "language_error": "Cannot change language"
      },
      "unexpected_error": "An unexpected error occurred! {error}"
    },
    "calendar": {
      "month": {
        "april": "April",
        "august": "August",
        "december": "December",
        "february": "February",
        "january": "January",
        "july": "July",
        "june": "June",
        "march": "March",
        "may": "May",
        "november": "November",
        "october": "October",
        "september": "September"
      },
      "month-shorthand": {
        "Apr": "Apr",
        "Aug": "Aug",
        "Dec": "Dec",
        "Feb": "Feb",
        "Jan": "Jan",
        "Jul": "Jul",
        "Jun": "Jun",
        "Mar": "Mar",
        "May": "May",
        "Nov": "Nov",
        "Oct": "Oct",
        "Sep": "Sep"
      }
    },
    "component": {
      "abroad-actions": {
        "delete": "Delete"
      },
      "absence-table": {
        "absence": "Absence",
        "duration": "Duration",
        "no_absences": "You have no upcoming absences."
      },
      "calendar_month": {
        "friday": "Fri",
        "monday": "Mon",
        "saturday": "Sat",
        "sunday": "Sun",
        "thursday": "Thu",
        "tuesday": "Tue",
        "wednesday": "Wed"
      },
      "date_picker": {
        "use_today": "Use Today"
      },
      "project-assignment-table": {
        "no_assignments": "Currently no project assignments available.",
        "project_name": "Project",
        "timeframe": "Timeframe"
      },
      "project-details": {
        "actions": {
          "cancel": "Cancel",
          "delete": "Delete",
          "edit": "Edit",
          "save": "Save",
          "to-project-overview": "To project overview"
        },
        "auto_approval": "Auto Approval",
        "auto_withdrawal": "Auto Withdrawal",
        "client-id": "Techn. Client-Id",
        "ldap_group": "LDAP Group",
        "pan-list": "Projektansprechpartner",
        "phase": "Phase",
        "project_key": "Project key",
        "project_name": "Name",
        "status": "Status"
      },
      "range_picker": {
        "day": "Day",
        "month": "Month",
        "view_end": "View End",
        "view_start": "View Start",
        "year": "Year"
      }
    },
    "data_model": {
      "absence": {
        "comment": "Comment",
        "daysOff": {
          "day": "Day",
          "days": "Days",
          "daysTooltip": "Note that it just displays the total absence days including weekends and holidays.",
          "halfDay": "half",
          "halfDays": "half"
        },
        "employee": "Employee",
        "endDate": "End date",
        "half_day": "Half Day",
        "startDate": "Start date",
        "status": "Status",
        "type": "Type"
      },
      "absence_status": {
        "approved": "Approved",
        "draft": "Draft",
        "new": "New",
        "rejected": "Rejected",
        "submitted": "Submitted",
        "withdrawn": "Withdrawn"
      },
      "absence_type": {
        "holiday": "Holiday",
        "illness": "Illness",
        "none": "",
        "project_absence": "Project Absence",
        "special_absence": "Special Absence",
        "techday": "Techday",
        "training": "Training",
        "vacation": "Vacation"
      }
    },
    "language": {
      "de-de": "Deutsch",
      "en-us": "English"
    },
    "modal": {
      "absence": {
        "action": {
          "cancel": "Cancel",
          "submit": "Submit"
        },
        "child_name": "Name of the child",
        "end_date": "End Date",
        "send_mail": "Send Mail",
        "start_date": "Start Date",
        "status": "Absence Status",
        "title": "Edit absence",
        "type": "Absence Type"
      },
      "confirmation": {
        "action": {
          "no": "No, I'm not.",
          "yes": "Oh yeah!"
        },
        "defaultTitle": "Confirmation required",
        "description": "Are you sure?"
      }
    },
    "page": {
      "abroad": {
        "description": "Overview of days worked abroad. If your main work-location specified in your contract is home office, enter any day that you work while not in home office, even when working in your home country.",
        "new": {
          "endDate": "To",
          "save": "Save",
          "startDate": "From"
        },
        "table": {
          "actions": "Actions",
          "endDate": "To",
          "location": "Location",
          "startDate": "From"
        },
        "title": "Abroad Overview"
      },
      "abroad-reporting": {
        "table": {
          "employee": "Employee",
          "endDate": "To",
          "location": "Location",
          "no_data": "No data available",
          "startDate": "From"
        },
        "title": "Abroad Reporting"
      },
      "absences": {
        "absences": {
          "new_illness": "New Illness",
          "new_project_absence": "New Project Absence",
          "new_special_absence": "New Special Absence",
          "new_techday": "New Techday",
          "new_training": "New Training",
          "new_vacation": "New Vacation"
        },
        "dialog": {
          "confirmDeleteAbsence": {
            "title": "Delete Absence"
          },
          "edit": {
            "action": {
              "cancel": "Cancel",
              "reset": "Reset",
              "save": "Submit"
            },
            "approved": "Approved",
            "half_day": "Half Day",
            "send_mail": "Send Mail",
            "title": "Edit Absences"
          },
          "history": {
            "action": {
              "close": "Close"
            },
            "column": {
              "change_comment": "Change comment",
              "change_date": "Change date",
              "duration": "Duration",
              "end_date": "End date",
              "processor": "Processor",
              "start_date": "Start date",
              "status": "Status",
              "type": "Type"
            },
            "title": "Absence History"
          }
        },
        "end_date": "End Date",
        "menu": {
          "accept_withdrawal": "Accept withrawal",
          "approve": "Approve",
          "cancel_modify": "Cancel modify",
          "cancel_moving": "Cancel moving",
          "change": "Modify",
          "change_type": {
            "type_illness": "Change Type to Illness",
            "type_project_absence": "Change Type to Project Absence",
            "type_special_absence": "Change Type to Special Absence",
            "type_techday": "Change Type to Techday",
            "type_training": "Change Type to Training",
            "type_vacation": "Change Type to Vacation"
          },
          "delete": "Delete",
          "move": "Move",
          "reapprove": "Re-approve",
          "reject": "Reject",
          "reset_to_approved": "Reset to approved",
          "show_history": "Show history",
          "submit": "Submit",
          "submit_moving": "Move here"
        },
        "more_than_one_absence": "There is more than one absence:",
        "select_period": "Select Period",
        "select_period_prompt": "<None>",
        "select_project": "Project",
        "select_project_prompt": "Select Project",
        "select_team": "Team",
        "start_date": "Start Date",
        "title": "Absences"
      },
      "admin-absences": {
        "absences": "Error retrieving absences from server",
        "already_techday": "Already a techday. Select another day!",
        "comment": "Please add a comment",
        "holidays": "Error retrieving holidays from server",
        "no_data": "No Data",
        "no_friday": "Select a friday!",
        "project_periods_retrieve": "Error retrieving named periods for project",
        "projects_retrieve": "Error retrieving projects from server",
        "select_current": "Current period",
        "select_team": "<Select Team>",
        "set_end": "Set end date",
        "set_start": "Set start date",
        "team_all": "<All Teams>",
        "team_none": "<No Team>",
        "team_surname": "<Surname>",
        "warning_too_big": "Showing all project members might take longer."
      },
      "admin-retreats": {
        "dialog": {
          "confirm_delete_retreat_title": "Delete Retreat"
        },
        "empty_fields": "Bitte alle Felder ausfüllen.",
        "friday": "Day has to be a Friday!",
        "workday": "Day must not be a Sunday!"
      },
      "admin-techdays": {
        "create_error": "Error creating Techdays for {year}",
        "created": "{count} Techdays for {year} created",
        "fixed_retrieve": "Error retrieving fixed Techdays from server",
        "friday": "Day has to be a Friday!"
      },
      "admin-utils": {
        "employee_select": "Please select an employee",
        "mail_error": "Mail sending error",
        "project_select": "Please select a project",
        "projects_retrieve": "Error retrieving projects from server"
      },
      "admin_utils": {
        "action": {
          "send": "Send",
          "test": "Test"
        },
        "addtional_comment": "Additional Comment",
        "cc_to_project": "CC Project",
        "for_employee": "For Employee",
        "for_project": "For Project",
        "no_project_selected": "<No project selected>",
        "test_result": "Test Result",
        "title": "Send Absence Info Mails"
      },
      "assignments": {
        "assignment_list_header": "Assigned Employees of",
        "assignment_synced": "Assignments synchronised from LDAP group {ldap_group}",
        "dialog_assignment": {
          "action": {
            "cancel": "Cancel",
            "delete": "Delete Assignment",
            "reset": "Reset",
            "save": "Save Changes",
            "save_new": "Save Assignment"
          },
          "employee": "Employee",
          "end_date": "End Date",
          "fixed_end_date": "Fixed end date",
          "header": "Details of Assignment of",
          "percentage": "Percentage",
          "percentage_placeholder": "% of assignment",
          "roles": "Roles",
          "team": "Team",
          "title": "Edit Assignment"
        },
        "dialog_team": {
          "action": {
            "cancel": "Cancel",
            "delete": "Delete Team",
            "save": "Save Changes",
            "save_new": "Save Team"
          },
          "name": "Name",
          "name_placeholder": "Team Name",
          "title": "Edit Team"
        },
        "dialog_team_delete_confirm": {
          "description": "Deleting a team also ends all assignments to that team!",
          "title": "Confirm Team Deletion"
        },
        "employee_select": "Please select an employee",
        "filter": "Filter",
        "project": {
          "filter": "Filter",
          "header": "Project",
          "no_project_selected": "No Project Selected",
          "select_project": "Select Project"
        },
        "project_select": "Please select an already existing project",
        "projects_retrieve": "Error retrieving projects from server",
        "team": {
          "action": {
            "edit_team": "Edit Team",
            "new_assignment": "New Assignment",
            "new_team": "New Team"
          },
          "filter": "Filter",
          "header": "Teams",
          "no_project_selected": "Please select a project first",
          "select_team": "Select a team",
          "show_all_members": "Show all project members",
          "teams_of": "Teams of {project}"
        },
        "title": "Assignments",
        "types_retrieve": "Error retrieving types from server"
      },
      "colleagues": {
        "absences_retrieve": "Error retrieving absence status from server",
        "return": "Expected to be back in the project on {date}.",
        "search": "Search",
        "status": {
          "not_available": "Not present today.",
          "present": "Present today.",
          "project_absent": "Not working for the project today.",
          "techday": "On Techday today."
        },
        "title": "Query Colleague"
      },
      "dashboard": {
        "absences": "Error retrieving own absences from server",
        "action": {
          "accept_withdrawal": "Accept withdrawal",
          "approve": "Approve",
          "child_ill_today": "Child is sick today",
          "edit": "Edit",
          "extend_illness": "Submit changes",
          "ill_today": "Call in sick today",
          "reject": "Reject",
          "reset_to_approved": "Reset to approved"
        },
        "actionable": "Error retrieving actionable absences from server",
        "expand": "Expand",
        "section": {
          "actionable_absences": {
            "actions": "Actions",
            "auto_approval_status": {
              "can_be_auto_approved": "Will be auto-approved after 5 work days.",
              "not_a_vacation": "Only vacations can be auto-approved",
              "project_with_no_auto_approval": "The employee has a project which blocks auto-approval",
              "too_long": "Can't be auto-approved because absence is longer than 14 days",
              "too_recent": "Absence too recent. Absences submitted within 5 work days of the start date can't be auto-approved"
            },
            "duration": "Duration",
            "hint": {
              "text": {
                "can_be_auto_approved": "Automatic approval is activated",
                "not_a_vacation": "Automatic approval is deactivated",
                "project_with_no_auto_approval": "Automatic approval is deactivated",
                "too_long": "Automatic approval is deactivated",
                "too_recent": "Automatic approval is deactivated"
              },
              "title": "Hint",
              "tooltip": "The duration of this absence is longer than two weeks or it has been submitted too late. It won't be approved automatically"
            },
            "no_absences": "No absences need your attention.",
            "project": "Project",
            "team": "Team",
            "title": "Absences Needing Attention"
          },
          "current_illness": {
            "date_pick": "Change end date of the current illness to",
            "title": "Current Illness"
          },
          "my_absences": {
            "title": "My Upcoming Absences"
          }
        },
        "shrink": "Shrink",
        "title": "Offline"
      },
      "error": {
        "back_to": "Go back to the",
        "dashboard": "dashboard",
        "message": "Oops, something went wrong."
      },
      "my_absences": {
        "absence": {
          "comment": "Comment",
          "comment_missing": "Please add a comment",
          "documentation": "Documentation",
          "end_date": "End Date",
          "end_date_before_start_date": "The end date must be after the start date",
          "half_day": "Half Day",
          "info_overtime_days": "Note: If you take days off using overtime hours, you should submit a vacation.",
          "info_types": "For more information about the different types of absences: see ",
          "new_comment": "New comment",
          "previous_comment": "Previous Comment",
          "start_date": "Start Date",
          "start_date_missing": "Please choose a start date",
          "status": "Status",
          "type": "Type",
          "type_missing": "Please select a type"
        },
        "absences_retrieve": "Error retrieving absences from server",
        "action": {
          "abort": "Abort",
          "delete": "Delete",
          "reset": "Reset to Draft",
          "resubmit": "Resubmit",
          "save_draft": "Save Draft",
          "show_history": "Show History",
          "submit": "Submit",
          "unselect": "Unselect",
          "withdraw": "Withdraw"
        },
        "approved_retrieve": "Error retrieving number of approved vacation days",
        "create_absence_on_techday": "Create Absence on Techday",
        "create_new_absence": "Create new absence",
        "edit_absence": "Edit absence",
        "holidays_retrieve": "Error retrieving holidays from server",
        "messages": {
          "overlapping": "The absence would overlap with another one. May be you want to enter a new Illness?"
        },
        "section": {
          "legend": {
            "status": {
              "approved": "Approved",
              "cancelled": "Cancelled",
              "draft": "Draft",
              "rejected_cancelled": "Rejected/Cancelled",
              "standard": "Standard",
              "submitted": "Submitted",
              "withdrawn": "Withdrawn"
            },
            "title": "Legend",
            "type": {
              "halfDayAbsence": "Half day",
              "holiday": "Holiday",
              "illness": "Illness",
              "mandatory": "To be kept free",
              "misc": "Misc",
              "normal_day": "Normal Day",
              "project_absence": "Project Absence",
              "special_absence": "Special Absence",
              "techday": "Techday",
              "today": "Today",
              "training": "Training",
              "vacation": "Vacation",
              "weekend": "Weekend"
            }
          },
          "summary": {
            "count": "Count",
            "no_vacation": "You currently have no approved vacation days for ongoing year.",
            "title": "Summary Of Approved Vacation Days",
            "year": "Year"
          }
        },
        "select": "Select",
        "title": "Calendar"
      },
      "pans": {
        "action": {
          "cancel": "Cancel",
          "delete": "Delete",
          "save": "Save"
        },
        "employee": "Employee",
        "employee_select": "Please select an employee",
        "new_pan": "New PAn",
        "project_pans": "Error retrieving PAns for project",
        "project_select": "Please select a project",
        "projects_retrieve": "Error retrieving projects from server",
        "select_pan": "Select PAn",
        "select_project": "Select Project",
        "select_project_prompt": "<No Project selected>",
        "title": "PAn"
      },
      "periods": {
        "action": {
          "cancel": "Cancel",
          "delete": "Delete",
          "new": "New Period",
          "save": "Save"
        },
        "end_date": "End Date",
        "filter": "Filter",
        "filter_placeholder": "",
        "messages": {
          "project_name": "Please specify a name",
          "project_periods_retrieve": "Error retrieving named periods for project",
          "project_select": "Please select an existing project",
          "projects_retrieve": "Error retrieving projects from server"
        },
        "name": "Name",
        "name_placeholder": "Period Name",
        "project": "Project",
        "select_period": "Select Named Period",
        "select_project": "Select Project",
        "select_project_prompt": "<No Project selected>",
        "start_date": "Start Date",
        "title": "Named Period"
      },
      "profile": {
        "absences": "Absences",
        "code": "LDAP Code",
        "error": {
          "something-went-wrong": "Something went wrong. The profile couldn't be loaded."
        },
        "hideTechdays": "Hide Techdays",
        "name": "Name",
        "profile": "Profile",
        "projects": "Projects",
        "showTechdays": "Show Techdays"
      },
      "projects": {
        "action": {
          "cancel": "Cancel",
          "delete": "Delete Project",
          "edit": "Edit Project",
          "new": "New Project",
          "save": "Save Changes",
          "save_new": "Save Project"
        },
        "auto_approval": "Automatic approval of vacations after 5 working days",
        "auto_withdrawal": "Withdrawal of absences without approval",
        "client_id": "Internal client Id",
        "client_id_placeholder": "Technical internal client Id",
        "default_project_name": "New Project",
        "employee": "Employee",
        "filter": "Filter by project name...",
        "ldap": "LDAP Group",
        "ldap_placeholder": "Project LDAP Group",
        "project_key": "Project key",
        "project_key_placeholder": "External referencable project key",
        "projects_retrieve": "Error retrieving projects from server",
        "select_project": "Select Project",
        "select_project_prompt": "<No Project selected>",
        "title": "Projects"
      },
      "reporting": {
        "as_of_label": "As of",
        "capacity_retrieve": "Error retrieving capacity for project",
        "date_from": "From",
        "date_to": "To",
        "diff": "Diff",
        "diff_to": "Diff to",
        "download": "Download Excel",
        "download_failed": "Download failed",
        "export_to_excel": "Export to Excel",
        "no_date": "Select a date!",
        "number_format": "Number Format",
        "project": "Project",
        "project_periods_retrieve": "Error retrieving named periods for project",
        "project_prompt": "Select Project",
        "projects_retrieve": "Error retrieving projects from server",
        "report": {
          "column": {
            "employee": "Employee",
            "gross_days": "Gross Days",
            "illness": "Illness",
            "net_days": "Net Days",
            "project": "Project",
            "project_absence": "Project Absence",
            "role": "Role",
            "special_absence": "Special Absence",
            "team": "Team",
            "techday": "Techday",
            "training": "Training",
            "vacation": "Vacation"
          }
        },
        "roles": "Roles",
        "select_absenceTypes": "Select Absencetypes",
        "select_period": "Select Period",
        "select_period_prompt": "<None>",
        "select_site": "Select Site",
        "show_by": "Show by",
        "show_individuals": "Show Individuals",
        "sites": "Error retrieving sites from server",
        "subtitle": "Excelexport of absences",
        "teams": "Teams",
        "title": "Reporting"
      },
      "retreat": {
        "action": {
          "cancel": "Cancel",
          "create_new": "Add new Retreat",
          "delete_selected": "Delete selected Retreat",
          "save": "Save"
        },
        "details_section": {
          "date1": "Day 1",
          "date2": "Day 2",
          "date3": "Day 3",
          "date4": "Day 4",
          "ldap1": "Group 1",
          "ldap2": "Group 2",
          "ldap3": "Group 3",
          "ldap4": "Group 4",
          "name": "Retreat-Name",
          "name_description": "Fill in name",
          "replaced13": "Cycle 1/3",
          "replaced24": "Cycle 2/4",
          "title": {
            "create": "Create Retreat",
            "date": "Date",
            "edit": "Edit Retreat",
            "fourthDay": "Has four days",
            "hasReplacementTechday": "Replacement Techday for absentees of the Retreat",
            "ldap": "LDAP Group",
            "new": "Create new Retreat",
            "replaced": "Replaced Techdays"
          }
        },
        "table_title": "Overview",
        "title": "Retreats"
      },
      "roles": {
        "action": {
          "cancel": "Cancel",
          "delete": "Delete",
          "new": "New Role",
          "save": "Save"
        },
        "description": "Description",
        "name": "Name",
        "name_placeholder": "Role Name",
        "project": "Project",
        "title": "Project-Roles"
      },
      "settings": {
        "action": {
          "save": "Save"
        },
        "choose_locale_error": "Please choose a language.",
        "configure_actionable_absences": "Configure PAn absences",
        "configure_actionable_absences_explanation": "Decide which information on absences you want to see without expanding an absence.",
        "current_site": "Current Location",
        "hint_team_only_with_projekt": "(The team can only be shown, if the project is shown as well.)",
        "language_settings": "Language Settings",
        "locale_changed_success": "Language changed.",
        "select_language": "Select Language",
        "sharing_absences_with_project": "Share absences with project",
        "sharing_absences_with_project_explanation": "This setting controls whether colleagues from your team or project (if there are no sub teams) can see your presence status in a team calendar for the near future (e.g. two weeks). When your presence status is shared, colleagues can see  whether you are present, absent (but not the reason for the absence, e.g. vacation or illness), project absent (e.g. on a training) or on Techday. Otherwise your status is displayed as unknown. This settings does not affect the query for the presence status of any given colleague for today.",
        "site": "Working Location",
        "timezone_settings": "Timezone Settings",
        "title": "Personal Settings"
      },
      "team_calendar": {
        "error": "The calendar could not be loaded.",
        "legend": {
          "absent": "Absent",
          "holiday": "Holiday",
          "project_absent": "Absent from project",
          "techday": "Techday",
          "title": "Legend",
          "today": "Today",
          "weekend": "Weekend"
        },
        "member_not_sharing": "Not sharing absence data",
        "no_data": "No team information was found.",
        "title": "Team Calendar"
      },
      "techdays": {
        "section_fixed_techday": {
          "action": {
            "cancel": "Cancel",
            "save": "Save"
          },
          "fixed_date": "Fixed Date",
          "fixed_techdays": "Fixed Techdays",
          "name": "Name",
          "name_description": "Description",
          "new_fixed_techday": "New fixed Techday",
          "replaced13": "Replaced Techday 1/3",
          "replaced24": "Replaced Techday 2/4",
          "title": "Fixed Techdays"
        }
      }
    },
    "project-phase": {
      "CURRENT": "Active",
      "FORMER": "Former",
      "NOT_SET": "---",
      "PLANNED": "Planned"
    },
    "project-status": {
      "CRITICAL": "Red",
      "NOT_SET": "---",
      "OKAY": "Green",
      "WARNING": "Amber"
    },
    "rest": {
      "absence_not_found": "Absence with id {id} could not be found",
      "absence_type_change_only_for_draft": "Absence type can only be changed for draft",
      "assignment_not_found": "Assignment with id {id} could not be found",
      "assignment_still_running": "There are still currently running assignments with this role. Please terminate them before deleting this role.",
      "comment_mandatory": "Comment is mandatory for selected absence type.",
      "delete_yourself": "You must not delete yourself",
      "employee_cannot_be_changed": "Employee cannot be changed",
      "employee_empty_code": "Employee code cannot be empty",
      "employee_empty_name": "Employee name cannot be empty",
      "employee_exists": "Employee with same code already exists",
      "employee_non_db": "Only internally tracked employees can be updated",
      "employee_not_found": "Employee not found",
      "empty_id_for_new": "Id has to be empty for new Entity",
      "fixed_techday_not_found": "Fixed techday with id {id} could not be found",
      "id_needed_for_update": "Id has to be given for update and must match object",
      "invalid_new_absence_status": "Invalid status for creating a new Absence: status {status}",
      "invalid_new_end_date": "New end date is either in the past or too far in the future",
      "invalid_parameters": "The provided parameters were not correct",
      "invalid_percentage": "Assignment can only be between 0 and 100%",
      "invalid_update_of_absence_status": "Invalid update of absence status: {absence} is not valid after {existing}",
      "limit_positive": "Limit must be positive",
      "named_period_not_found": "Named period with id {id} could not be found",
      "no_absence_status": "No AbsenceStatus given",
      "no_absence_type": "No AbsenceType given",
      "no_assignment_type": "Assignment Type undefined",
      "no_changes_for_pan_from_ldap": "Modification of pans for projects from ldap not allowed",
      "no_employee": "No employee given or in database",
      "no_end_date": "End Date must be given",
      "no_entity_to_update": "Entity to update does not exist",
      "no_name": "No name given",
      "no_null": "null is not allowed",
      "no_project": "No project given or in database",
      "no_start_date": "Start Date must be given",
      "no_team": "No team given or in database",
      "not_admin": "Only admins can perform this operation.",
      "not_admin_or_in_project": "Only project admins and project members can perform this operation.",
      "not_logged_in": "Not logged in",
      "only_authorized": "Only authorized users can perform this operation",
      "only_self": "Only the respective employee can perform this operation",
      "overlapping_absences": "Absence would overlap another absence (drafts included)",
      "project_all_assignment": "Assignments to project All are not allowed",
      "project_cannot_be_changed": "Project cannot be changed",
      "project_empty_name": "Project name cannot be empty",
      "project_exists": "Project with same name already exists",
      "project_ldap_group_not_found": "Projects LDAP group could not be found",
      "project_settings_not_found": "Project configuration not found.",
      "retreat_ldap_group_not_found": "The specified Ldap group could not be found.",
      "retreat_number_of_days_changed": "The number of retreat days changed. This is not supported.",
      "team_empty_name": "Team name cannot be empty",
      "team_exists": "Team with same name already exists in the project",
      "team_project_cannot_be_changed": "Project of team cannot be changed",
      "tech_days_only_1_day": "Techdays can only last one day",
      "techdays_description_missing": "Please give a description",
      "techdays_fixed_missing": "No fixed Techdays found for year {year}! Please create these first!",
      "techdays_friday": "Techday must be on a friday",
      "timespan_not_forever": "end of time span must not be forever",
      "timespan_order": "end of time span is before start of time span",
      "unexpected_error": "An unexpected error occurred! {error}",
      "validation_error": "Validation error",
      "wrong_date_order": "Start date must be before end date"
    },
    "site": {
      "AUGSBURG": "Augsburg",
      "AUSTRALIA_VICTORIA": "Australia / Victoria",
      "AUSTRIA": "Austria",
      "BADEN_WUERTTEMBERG": "Baden-Württemberg",
      "BAYERN_EV": "Bavaria (ev.)",
      "BAYERN_KATH": "Bavaria (kath.)",
      "BERLIN": "Berlin",
      "BRANDENBURG": "Brandenburg",
      "BREMEN": "Bremen",
      "BULGARIA": "Bulgaria",
      "ETTENHEIM": "Ettenheim",
      "HAMBURG": "Hamburg",
      "HESSEN": "Hesse",
      "HUNGARY": "Hungary",
      "KARLSRUHE": "Karlsruhe",
      "MECKLENBURG_VORPOMMERN": "Mecklenburg-Western Pomerania",
      "MUNICH": "Munich",
      "NIEDERSACHSEN": "Lower Saxony",
      "NORDRHEIN_WESTFALEN": "North Rhine-Westphalia",
      "NUERNBERG": "Nuremberg",
      "REGENSBURG": "Regensburg",
      "REUTLINGEN": "Reutlingen",
      "RHEINLAND_PFALZ": "Rhineland-Palatinate",
      "SAARLAND": "Saarland",
      "SACHSEN": "Saxony",
      "SACHSEN_ANHALT": "Saxony-Anhalt",
      "SCHLESWIG_HOLSTEIN": "Schleswig-Holstein",
      "STUTTGART": "Stuttgart",
      "SWITZERLAND_VAUD": "Switzerland / Vaud",
      "THUERINGEN": "Thuringia"
    }
  }]];
});